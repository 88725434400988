@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/mixins.scss';
   
.videoList {
    .adsItem,
    article { margin-bottom: 30px; }
    article { min-height: calc(100% - 30px); }
}
.link { letter-spacing: 0.4px; }

@media (max-width: 991px) {
    .videoList {
        .adsItem,
        article { margin-bottom: 16px; }
        article { min-height: calc(100% - 16px); }
    }
}