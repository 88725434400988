@import "@assets/scss/variables.scss";
@import "@assets/scss/mixins.scss";

.breadcrumb {
  .item {
    + .item {
      &::before {
        content: ">";
        float: none;
        padding-right: 0.5rem;
        color: var(--font-color);
      }
    }
    &.active {
      color: var(--theme-color-medium);
      max-width: 200px;
    }
  }
}

/***** Responsive CSS Start ******/
