@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';
   
.videoMain { width: 100%; height: 480px; }
.videoDesc p { white-space: break-spaces;}
.playlistBtn { width: 34px; }
.outlineBtn {
   > svg { margin-left: -2px;
        path { fill: var(--font-color); }
   }
   &.active { background: #fff; }
   &.bookmark {
    &.active {
      .icon { background: #fff;}
      svg path  { fill: var(--font-color); }
    }
    svg {
      path { fill: transparent; stroke: var(--font-color); }
    }
  }
}
.share::after { display: none; }

@media (min-width: 768px) and (max-width: 991px) {
    .videoMain { height: 380px; }
}

@media (max-width: 767px) {
    .videoMain { height: 300px; }
}

@media (max-width: 575px) {
    .videoMain { height: 220px; position: sticky; top: 0; z-index: 6; }
}